import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CustomBanner.module.css';

// Due to the layout structure, do not render the banner on the following pages
const disabledPages = ['SearchPage'];

const CustomBanner = props => {
  const { rootClassName, className, isAuthenticated, currentPage, messages } = props;
  const classes = classNames(rootClassName || css.root, className);

  const showBanner = !isAuthenticated && !disabledPages.includes(currentPage);

  return showBanner ? (
    <div className={classes}>
      {messages.map(message => (
        <p className={css.text}>{message}</p>
      ))}
      {/* <Button rootClassName={css.button} onClick={onLogout}>
        <FormattedMessage id="LimitedAccessBanner.logout" />
      </Button> */}
    </div>
  ) : null;
};

CustomBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentPage: null,
};

const { bool, string, array } = PropTypes;

CustomBanner.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  currentPage: string,
  messages: array.isRequired,
};

export default CustomBanner;
